import React from "react";
import SubHeader from "./SubHeader";
import MiniHeader from "./MiniHeader";

export default function Header({ className }) {
  return (
    <div className={`bg-brand-white ${className}`}>
      <MiniHeader />
      <SubHeader />
    </div>
  );
}
