const isProduction = process.env.NODE_ENV === "production";

// log the pageview with their URL
export const pageview = (url) => {
  if (!isProduction) return;
  window.gtag("config", process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
    page_path: url,
  });
};

// log specific events happening.
export const event = ({ action, params }) => {
  if (!isProduction) return;
  window.gtag("event", action, params);
};
