import Link from "next/link";

export default function LinkElement({ link, displayValue, Icon, currentPath }) {
  const isActive = currentPath === link;
  return (
    <Link href={link}>
      <a
        href="#"
        className={
          "text-xs uppercase py-3 font-bold flex gap-2 " +
          (isActive
            ? "text-brand-secondary hover:text-brand-primary"
            : "text-brand-dark hover:text-brand-mediumgray")
        }
      >
        <Icon
          fontSize={16}
          className={"text-md " + (isActive ? "opacity-90" : "text-gray-300")}
        />
        {displayValue}
      </a>
    </Link>
  );
}
