import Link from "next/link";

export default function HeaderElement({ url, content, Icon }) {
  return (
    <Link href={url || ""}>
      <a className="hover:text-brand-primary flex gap-2 items-center">
        <Icon fontSize={12} className="text-md text-brand-primary" />
        {content}
      </a>
    </Link>
  );
}
