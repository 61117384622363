import HeaderElement from "./HeaderElement";
import { useRouter } from "next/router";
import useUser from "~/common/hooks/useUser";
import {
  FaPhoneAlt,
  FaRegEnvelope,
  FaMapMarkerAlt,
  FaRegUser,
  FaFacebook,
  FaInstagram,
} from "react-icons/fa";
import { event as gaEvent } from "~/lib/ga";

const AccountHeader = () => {
  const { user, isLoading } = useUser();

  if (isLoading) {
    return <p>Cargando</p>;
  }

  if (!user) {
    return <p>Iniciar sesion</p>;
  }

  return <div className="line-clamp-1">{user.fullName}</div>;
};

export default function MiniHeader() {
  const router = useRouter();

  const completeRoutePath = router.asPath;

  return (
    <div className="text-white flex flex-col gap-4 md:px-28 lg:px-32 2xl:px-72 lg:flex-row lg:gap-0 items-center justify-between px-9 py-3 text-xs bg-brand-darkblue">
      <div className="hidden md:flex info items-center gap-4">
        <HeaderElement
          url="tel:6621940563"
          content="6621-94-0563"
          Icon={FaPhoneAlt}
        />
        <HeaderElement
          url="mailto:serviciosseminuevos@gmail.com"
          content="serviciosseminuevos@gmail.com"
          Icon={FaRegEnvelope}
        />
        <HeaderElement
          url={null}
          content="Alberto Gutierrez #357-A Esq. Callejón Llano. Colonia Olivares."
          Icon={FaMapMarkerAlt}
        />
      </div>
      <div className="actions flex gap-6 items-center">
        <div className="flex place-content-end md:w-52 w-40">
          <HeaderElement
            url="/admin"
            content={<AccountHeader />}
            Icon={FaRegUser}
          />
        </div>
        <a
          href="https://wa.me/5216621940563?text=Me%20interesa%20anunciar%20mi%20auto."
          target="_blank"
          onClick={() => {
            gaEvent({
              action: "anunciar_auto_click",
              params: {
                url: completeRoutePath,
              },
            });
          }}
          className="bg-brand-primary text-black font-bold px-2 py-1 rounded-lg"
          rel="noreferrer"
        >
          Anunciar mi auto
        </a>
        <div className="flex gap-2">
          <a
            className="p-1 gap-1 flex text-white bg-gradient-to-r from-blue-500 to-cyan-500 rounded-md"
            href={`https://www.facebook.com/seminuevossonora.compaginaoficial`}
            target="_blank"
            onClick={() => {
              gaEvent({
                action: "header_button_facebook",
                params: {},
              });
            }}
            rel="noreferrer"
          >
            <FaFacebook fontSize={16} />
          </a>

          <a
            href={`https://www.instagram.com/seminuevossonora/`}
            target="_blank"
            onClick={() => {
              gaEvent({
                action: "header_button_instagram",
                params: {},
              });
            }}
            className="p-1 flex gap-1 text-white bg-gradient-to-r from-orange-400 via-red-500 to-pink-500 rounded-md"
            rel="noreferrer"
          >
            <FaInstagram fontSize={16} />
          </a>
        </div>
      </div>
    </div>
  );
}
