import {
  FaPhoneAlt,
  FaRegEnvelope,
  FaMapMarkerAlt,
  FaRegUser,
} from "react-icons/fa";
import Link from "next/link";

function LinkElement({ url, content, Icon }) {
  return (
    <Link href={url || ""}>
      <a className="hover:text-brand-primary flex gap-2 items-center">
        {Icon && <Icon fontSize={12} className="text-md text-brand-primary" />}
        {content}
      </a>
    </Link>
  );
}

export default function Footer() {
  return (
    <div className="bg-brand-dark flex flex-col gap-12 border-brand-primary border-t-4 mt-20 md:flex-row w-full py-10 px-6 ">
      <div className="max-w-xs font-light text-white">
        <h3 className="text-2xl text-white py-6 font-bold">NOSOTROS</h3>
        <div className="flex flex-col gap-2">
          <p>Somos una empresa totalmente responsable</p>
          <LinkElement
            url={null}
            content="Alberto Gutierrez #357-A Esq. Callejón Llano. Colonia Olivares."
            Icon={FaMapMarkerAlt}
          />
          <LinkElement Icon={FaPhoneAlt} url={null} content="6621940563" />
          <LinkElement
            url={null}
            content=" serviciosseminuevos@gmail.com"
            Icon={FaRegEnvelope}
          />
        </div>
      </div>

      <div className="max-w-xs font-light text-white">
        <h3 className="text-2xl text-white font-bold py-6">CATEGORIAS</h3>
        <div className="flex flex-col gap-2">
          <LinkElement url={"/"} content="Inicio" Icon={null} />
          <LinkElement Icon={null} url={"/autos"} content="Autos" />
          <LinkElement
            url={{ pathname: "/negocios", query: { category: "Lote" } }}
            content="Lotes"
            Icon={null}
          />
        </div>
      </div>
    </div>
  );
}
