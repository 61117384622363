import Head from "next/head";

const DEFAULT_VALUES = {
  title: "Seminuevos Sonora",
  description: "Donde si vendes tu auto",
  image: process.env.URL + "/SS.jpg",
  url: process.env.URL,
};

export default function Metadata({ title, description, image, url }) {
  // TODO:Implementar og props, like description and title. By default use the title and description of page but if you pass a custom og tags use that instead.
  /**
   * og: {
   *    title: "",
   *    description: "",
   *    image: ""
   * }
   */
  const metaTitle = title ? title : DEFAULT_VALUES.title;
  const metaDescription = description
    ? description
    : DEFAULT_VALUES.description;
  const metaImage = image ? image : DEFAULT_VALUES.image;
  const metaUrl = url ? process.env.URL + url : DEFAULT_VALUES.url;

  return (
    <Head>
      <html lang="es" />
      <meta charSet="UTF-8" />
      <title>{metaTitle}</title>
      <meta property="description" content={metaDescription} />
      <meta property="author" content="Seminuevos Sonora" />

      <meta property="og:title" content={metaTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={metaImage} />
      <meta property="og:description" content={metaDescription} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={metaTitle} />
      <meta property="twitter:description" content={metaTitle} />
      <meta property="twitter:image" content={metaImage} />
      <meta property="twitter:url" content={metaUrl} />
      <meta
        property="viewport"
        content="initial-scale=1.0, width=device-width"
      />
      <link rel="canonical" href="https://seminuevossonora.com" />
    </Head>
  );
}
