import { useSession } from "next-auth/react";

export default function useUser() {
  const { data: session, status } = useSession();

  if (status === "loading") {
    return {
      user: null,
      isLoading: true,
    };
  }

  if (status === "unauthenticated") {
    return {
      user: null,
      isLoading: false,
    };
  }

  return {
    user: session.user,
    isLoading: false,
  };
}
