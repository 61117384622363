import React from "react";
import Link from "next/link";
import UserDropdown from "~/components/Dropdowns/user-dropdown";
import {
  FaTools,
  FaTv,
  FaClipboardCheck,
  FaBuilding,
  FaCarAlt,
  FaBars,
  FaCarSide,
  FaCode,
  FaUserAlt,
  FaTimes,
} from "react-icons/fa";
import { useRouter } from "next/router";
import LinkElement from "./link-element";
import { useSession } from "next-auth/react";

const ProjectName = "Seminuevos Sonora";

function UserDetails() {
  const { data, status } = useSession();
  if (status === "authenticated") {
    return (
      <div>
        <p className="text-sm">{data?.user?.fullName}</p>
      </div>
    );
  }
  return null;
}

export default function Sidebar() {
  const { data, status } = useSession();
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const router = useRouter();

  const currentPath = React.useMemo(() => {
    return router.pathname;
  }, [router]);

  if (status === "loading") {
    return <p>Cargando</p>;
  }

  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            {/* <i className="fas fa-bars"></i> */}
            <FaBars fontSize={16} />
          </button>
          {/* Brand */}
          <Link href="/">
            <a
              // href="#pablo"
              className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
            >
              {ProjectName}
            </a>
          </Link>
          <UserDetails />
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link href="/">
                    <a
                      // href="#pablo"
                      className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    >
                      {ProjectName}
                    </a>
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    {/* <i className="fas fa-times"></i> */}
                    <FaTimes fontSize={16} />
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="border-0 px-3 py-2 h-12 border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              {/* Admin Layout Pages */}
              Gestion
            </h6>
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <LinkElement
                  link="/admin"
                  displayValue="Dashboard"
                  currentPath={currentPath}
                  Icon={FaTv}
                />
              </li>

              <li className="items-center">
                <LinkElement
                  link="/admin/inventario"
                  displayValue="Inventario"
                  currentPath={currentPath}
                  Icon={FaCarSide}
                />
              </li>

              <li className="items-center">
                <LinkElement
                  link="/admin/autos"
                  displayValue="Autos"
                  currentPath={currentPath}
                  Icon={FaCarAlt}
                />
              </li>
              {data?.user?.profile.id === 2 ||
                (data?.user?.profile.id === 1 && (
                  <>
                    <li className="items-center">
                      <LinkElement
                        link="/admin/aprobacion"
                        displayValue="Aprobacion"
                        currentPath={currentPath}
                        Icon={FaClipboardCheck}
                      />
                    </li>
                    <li className="items-center">
                      <LinkElement
                        link="/admin/negocios"
                        displayValue="Negocios"
                        currentPath={currentPath}
                        Icon={FaBuilding}
                      />
                    </li>
                    <li className="items-center">
                      <LinkElement
                        link="/admin/users"
                        displayValue="Usuarios"
                        currentPath={currentPath}
                        Icon={FaUserAlt}
                      />
                    </li>
                    <li className="items-center">
                      <LinkElement
                        link="/admin/metricas"
                        displayValue="Codigo de Metrica"
                        currentPath={currentPath}
                        Icon={FaCode}
                      />
                    </li>
                  </>
                ))}
            </ul>

            {/* Divider */}
            {(data?.user?.profile.id === 1 || data?.user?.profile.id === 2) && (
              <>
                <hr className="my-4 md:min-w-full" />
                {/* Heading */}
                <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                  {/* Auth Layout Pages */}
                  Catalogos
                </h6>
                {/* Navigation */}

                <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                  <li className="items-center">
                    <LinkElement
                      link="/admin/catalogos/marca"
                      displayValue="Marcas"
                      currentPath={currentPath}
                      Icon={FaTools}
                    />
                  </li>
                  <li className="items-center">
                    <LinkElement
                      link="/admin/catalogos/modelo"
                      displayValue="Modelos"
                      currentPath={currentPath}
                      Icon={FaTools}
                    />
                  </li>

                  <li className="items-center">
                    <LinkElement
                      link="/admin/catalogos/transmision"
                      displayValue="Transmisiones"
                      currentPath={currentPath}
                      Icon={FaTools}
                    />
                  </li>

                  <li className="items-center">
                    <LinkElement
                      link="/admin/catalogos/vestidura"
                      displayValue="Vestiduras"
                      currentPath={currentPath}
                      Icon={FaTools}
                    />
                  </li>
                </ul>
              </>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}
